<template>
    <div class="row q-mb-xs">
        <span
            :class="
                props.row.last_dvm_action_at && props.value.value === 'draft' ? 'text-bold text-negative' : ''
            "
        >
            {{
                ['ers', 'oepc'].includes(props.row.valuation_type) && props.value.value === 'valued'
                    ? $t('status.completed')
                    : props.value.label
            }}
        </span>
        <re-tooltip
            v-if="props.row.last_dvm_action_at && props.value.value === 'draft'"
            :label="$t('monolith.dashboard.review_tooltip')"
            is-error
        ></re-tooltip>
    </div>
    <div class="row q-gutter-xs">
        <q-badge
            v-if="badges.includes('resubmitted') && isValuerOrTypeDispatcher"
            color="warning-bground"
            text-color="warning"
            multi-line
        >
            {{ $t('monolith.dashboard.badges.resubmitted') }}
        </q-badge>
        <q-badge
            v-if="
                badges.includes('needs_borrower_email') &&
                (curUser.hasRole('dispatcher', 'ovm') ||
                    curUser.hasRole('dispatcher', 'oepc') ||
                    props.row.owner.is_self)
            "
            color="warning"
            multi-line
        >
            {{ $t('monolith.dashboard.badges.needs_borrower_email') }}
        </q-badge>
        <q-badge
            v-if="badges.includes('needs_appointment_confirmation') && isValuerOrTypeDispatcher"
            color="warning"
            multi-line
        >
            {{ $t('monolith.dashboard.badges.needs_appointment_confirmation') }}
        </q-badge>
        <q-badge
            v-if="
                badges.includes('needs_borrower_action') &&
                (curUser.hasRole('dispatcher', 'ovm') || curUser.hasRole('dispatcher', 'oepc'))
            "
            color="warning"
        >
            {{ $t('monolith.dashboard.badges.needs_borrower_action') }}
        </q-badge>
        <q-badge
            v-if="
                badges.includes('appointment_confirmed') &&
                (curUser.hasRole('dispatcher', 'ovm') ||
                    curUser.hasRole('dispatcher', 'oepc') ||
                    props.row.owner.is_self ||
                    props.row.valuer?.is_self)
            "
            color="positive"
        >
            {{ $t('monolith.dashboard.badges.appointment_confirmed') }}
        </q-badge>
    </div>
</template>
<script setup>
import { inject, computed } from 'vue'

// don't automatically insert attrs in root div:
defineOptions({ inheritAttrs: false })

const props = defineProps({
    row: {
        type: Object,
        required: true,
    },
    value: {
        type: Object,
        required: true,
    },
})

const curUser = inject('curUser')

const badges = computed(() => props.value.badges)

const isValuerOrTypeDispatcher = computed(
    () => curUser.hasRole('dispatcher', props.row.valuation_type) || props.row.valuer?.is_self
)
</script>
<style lang="scss" scoped>
.q-badge {
    word-wrap: normal;
    white-space: normal;
    word-break: normal;
}
</style>
